<template>
  <header id="brokerHeader">
    <div class="container-fluid">
      <router-link class="logo"
                   to="broker-dashboard">
        <img src="/img/hbl-new-logo-black.png">
      </router-link>
      <nav :class="{ 'main-nav': true, open: toggleMenu }">
        <ul class="main-menu">
          <li>
            <router-link to="broker-dashboard">
              HOME
            </router-link>
          </li>
          <li>
            <router-link to="broker-reservations">
              RESERVATIONS
            </router-link>
          </li>
          <li>
            <router-link to="broker-listings">
              LISTINGS
            </router-link>
          </li>
          <li>
            <router-link to="broker-charges">
              CHARGES
            </router-link>
          </li>
          <li>
            <router-link to="broker-earnings">
              Earnings
            </router-link>
          </li>
        </ul>
        <div class="user-log-in"
             v-if="getUser">
          <a href="javascript:;"
             class="user-information"
             @click="toggleMenu = !toggleMenu">
            <figure>
              <i class="icon-user" />
            </figure>
            {{ getUser.name }} {{ getUser.surname }}
            <i class="icon-cheveron-down" />
          </a>
          <ul id="user-menu"
              :class="{ smoothTransition : toggleMenu }">
            <li>
              <router-link :to="{ name: 'home' }">
                Go to Homebelike
              </router-link>
            </li>
            <li>
              <a href="javascript:;"
                 @click="logOut"
                 class="text-danger">Sign Out</a>
            </li>
          </ul>
        </div>
      </nav>
      <button class="btn-nav"
              @click="showMenu();">
        <span />
      </button>
    </div>
  </header>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'HeaderBrokerComponent',
  data() {
    return {
      toggleMenu: false,
    };
  },
  methods: {
    showMenu() {
      this.toggleMenu = !this.toggleMenu;
    },
    ...mapActions([
      'requestLogOut'
    ]),
    logOut() {
      this.requestLogOut().then(() => {
        this.$router.push('/');
        window.location.reload();
      });
    }
  },
  computed: {
    ...mapGetters([
      'getUser',
    ]),
  }
};
</script>

<style>
.smoothTransition {
  transition: all .5s linear;
  display: block!important;
}
</style>
